
import axios from 'axios'



const get = async (url, data) => {
    return axios.get(url, {
        params: data
    })
}

const post = async (url, data) => {
    return axios.post(url, data)
}

export default {
    getOrder: async (dataId) => {
        let res = await get('../api/order/' + dataId)
        let resp = res.data
        if (resp.success) {
            return resp.data
        }
        return {}
    },
    getLogs: async () => {
        let res = await get('log/list')
        let resp = res.data
        if (resp.success) {
            return resp.data
        }
        return {}
    },
    getLogDetail: async (file) => {
        let res = await post('log/detail', file)
        let resp = res.data
        if (resp.success) {
            return resp.data
        }
        return {}
    }
}
